import React,{useState, useEffect} from 'react'

const timeOut = {
    'minutes':'00',
    'seconds':'00',
  }
  let timeRemaining = 120;
  const EmailVerifyCountDown = () => {
    const [timer, setTimer] = useState(timeRemaining)
        useEffect(()=>{
          const interValId = setInterval(()=>{
      updateCountdown()
          },1000)
          if(timer===0 ){
            clearInterval(interValId)
          }
          return ()=> clearInterval(interValId)
        },[timer])
        
      function updateCountdown (){
      console.log('Hello world')
      timeRemaining--;
     setTimer(timeRemaining)
      }
      

  return (
    <p>
      {timer}
    </p>
  )
}

export default EmailVerifyCountDown
