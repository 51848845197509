import React from 'react'
import styled from 'styled-components'
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";


const DashTableModal = (props) => {
    
  return (
    <>
  <Backdrop/>
      <Modal>
        <Icon>
            <CloseIcon />
        </Icon>
        <div style={{'marginTop':'1rem'}}>
            <h4 style={{'textAlign':'right'}}>Payment:</h4>
            <h4>5071578854</h4>
        </div>
        <div>
            <p>Order id:</p>
            <span>{props.order_id}</span>
        </div>
        <div>
            <p>Order description:</p>
            <span>{props.order_desc}</span>
        </div>
        <div>
            <p>Original price:</p>
            <span>{props.original_price}</span>
        </div>
        <div>
            <p>Pay price:</p>
            <span>{props.pay_price}</span>
        </div>
        <div>
            <p>Actually paid:</p>
            <span>{props.actual_paid}</span>
        </div>
        <div>
            <p>Outcome price:</p>
            <span>{props.outcome_price}</span>
        </div>
        <div>
            <p>Status:</p>
            <span>{props.status}</span>
        </div>
        <div>
            <p>Created at:</p>
            <span>{props.created_at}</span>
        </div>
        <div>
            <p>Updated at:</p>
            <span>{props.updated_at}</span>
        </div>
        <div>
            <p>Payin address:</p>
            <span>{props.payin_address}</span>
        </div>
        <div>
            <p>Payout address:</p>
            <span>{props.payout_address}</span>
        </div>
        
       <button>Ok</button>
      </Modal>
    </>
  )
}

const Icon = styled.div`
display:flex;
width:100%;
justify-content:flex-end;
justify-self:flex-end;
align-self:flex-end;
align-content:flex-end;

`
const CloseIcon = styled(CloseOutlinedIcon)`
display:flex;
align-self:flex-end;
justify-self:flex-end;
margin-left:5rem;
`
const Modal = styled.div`
  position: absolute;
  top: 5%;
  z-index: 20;
  padding:1rem;
  left: 30%;
  display: flex;
  margin-bottom:3rem;
  justify-content: center;
  transition: 1s ease-in-out;
  background:#ffffff;
  box-shadow:0 4px 8px 0 rgb(0 0 0 / 15%);
  display:flex;
  flex-direction:column;
  align-content:center;
  justify-content:space-around;
  width:400px;
  border-radius:5px;
//   row-gap:10px;
  @media (max-width: 1024px) {
    left: 30%;
    top: 5%;
  }
  @media (max-width: 719px) {
    left: 15%;
  }
  @media (max-width: 570px) {
    left: 10%;
  }
  @media (max-width: 500px) {
    left: 2%;
    width:95%;
  }
  div{
    display:grid;
    grid-template-columns:repeat(2, 45%);
    column-gap:5%;
    justify-content:center;
    h4{
        margin-bottom:1rem;
    }
    p{
        text-align:right;
        
    }
    span{
        text-align:left;
        font-weight:bold;

    }
  }
  button {
    width: 100%;
    text-align: center;
    border: none;
    background-color: #003559;
    padding: 8px;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 10px;
    /* opacity: 0.6; */
    transition: 0.3s ease-in-out;
    :hover {
      background-color: #061a40;
      color: #fff;
      /* opacity: 1; */
    }
  }
`;
const Backdrop = styled.div`
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 100%, transparent);
  z-index: 15;
  left: 0;
  position: fixed;
  top: 0;
`;
export default DashTableModal
