import React, {  useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import axios from "../../../../api/axios";
import { Button } from "react-bootstrap";
import { RemoveRedEye, SettingsPhoneTwoTone } from "@mui/icons-material";
import DashTableModal from "./DashTableModal";
import styled from 'styled-components'
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Modal from 'react-bootstrap/Modal';
import { CircularProgress } from "@mui/material";




const user_id = localStorage.getItem("userId");
let data2;
let success;

function DashTable() {
    
    const [show, setShow] = useState(false);
    const [data,setData] = useState([])
    const [data2, setData2]= useState([]);
    const [loading,setLoading] = useState(false);
    const [order_id,setOrderId]=useState('');
    const [success,setSuccess] = useState(false)
    const [fetching,setFetching] = useState(false)
    
    
    
   
const columns = [
   
    {
        name: 'Order  ID',
        selector: row => row.order_id,
        sortable: true,
    },
    {
        name: 'Amount Sent',
        selector: row => row.amount,
        sortable: true,
    },
    {
        name: 'Amount Received',
        selector: row => row.amount_received,
        sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.created_at,
      sortable: true,
      button:true,
      conditionalCellStyles:[
        {
          when: row => row.created_at,
          style: {
              marginLeft:'-10px'
            
          },
      },
    ]
  },
 
  {
    name: 'Status',
    selector: row => row.status,
    conditionalCellStyles: [
      {
          when: row => row.status === 'completed',
          style: {
              
              color: 'green',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
      {
          when: row => row.status === 'pending',
          style: {
              
              color: 'black',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
      {
          when: row => row.status === 'partially_paid',
          style: {
              
              color: 'orange',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
      {
          when: row => row.status === 'incoming',
          style: {
              
              color: 'blue',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
      {
          when: row => row.status === 'cancelled',
          style: {
              
              color: 'red',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
  ],
    sortable: true,
},
{
    name: 'Action',
    sortable: false,
     selector:(row) =>

    <Button  style={{'background':'none','border':'none','color':'#061a40'}} onClick={() => {
            setOrderId(row.order_id);
           
           
         }} ><RemoveRedEye /></Button>

 
},
];
const handleClose = () => {
    setShow(false);
};


const fetchTableData = () => {
 
  setFetching(true)
  axios
  .post('/?action=get_all_tx', null, {
      params: {
          user_id,
        },
    })
    .then((resp) => {
        setFetching(false)
        //   console.log(resp.data.data);
        setData(resp.data.data)
        //   console.log(data)
        setSuccess(true)
        
        
    })
    .catch((err) => {
        setFetching(false)
     
    });
};
const customStyles = {
  rows: {
      style: {
          minHeight: '50px', // override the row height
      },
  },
  headCells: {
      style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
          textAlign:'center'
      },
  },
  cells: {
      style: {
          paddingLeft: '10px', // override the cell padding for data cells
          paddingRight: '10px',

      },
  },
};


function modalData (){
    
    setLoading(true)

    axios
      .post('/?action=get_single_tx', null, {
        params: {
            user_id,
            order_id,
        },
      })
      .then((resp) => {
        setLoading(false)
        if(resp.data.status_code===200){
            setData2(resp.data.data)
            setShow(true)
           
        }
       
        
        
    })
    .catch((err) => {
        
        setLoading(false)
    });
};
useEffect(()=>{
   order_id && modalData()
},[order_id])
  useEffect(()=>{
   user_id && fetchTableData();
   console.clear()
  },[])

 
 

  
    return (
        <>
        {
            loading && <Backdrop />
        }
        {
            loading && <Loading />
        }
        <div>
            {
                fetching && <Loading/>
            }
        {
            success && <DataTable
            columns={columns}
            data={data}
            pagination
            customStyles={customStyles}/>
        }
        </div>
           
           <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Payment Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Mode>
          
        <div>
            <p>Order id:</p>
            <span>{data2[0]?.order_id}</span>
        </div>
        <div>
            <p>Order description:</p>
            <span>{data2[0]?.order_desc}</span>
        </div>
        <div>
            <p>Original price:</p>
            <span>{data2[0]?.original_price}</span>
        </div>
        <div>
            <p>Pay price:</p>
            <span>{data2[0]?.pay_price}</span>
        </div>
        <div>
            <p>Actually paid:</p>
            <span>{data2[0]?.actual_paid}</span>
        </div>
        <div>
            <p>Outcome price:</p>
            <span>{data2[0]?.outcome_price}</span>
        </div>
        <div>
            <p>Status:</p>
            <span>{data2[0]?.status}</span>
        </div>
        <div>
            <p>Created at:</p>
            <span>{data2[0]?.created_at}</span>
        </div>
        <div>
            <p>Updated at:</p>
            <span>{data2[0]?.updated_at}</span>
        </div>
        <div>
            <p>Payin address:</p>
            <span>{data2[0]?.payin_address}</span>
        </div>
        <div>
            <p>Payout address:</p>
            <span>{data2[0]?.payout_address}</span>
        </div>
        
            </Mode>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{'background':'#061a40','width':'100%'}} onClick={handleClose}>
           Okay
          </Button>
        </Modal.Footer>
      </Modal>
    
            </>
    );
};

const Mode= styled.div`
div{
        display:grid;
        grid-template-columns:32% 63%;
        column-gap:5%;
        justify-content:center;
        flex-wrap:wrap;
        h4{
            margin-bottom:1rem;
        }
        p{
            text-align:right;
            
        }
        span{
            // text-align:left;
            font-weight:bold;
            display:flex;
            font-size:14px;
            flex-wrap:wrap !important;
            
    
        }
      }
`
const Loading = styled(CircularProgress)`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  color: #fff !important;
  font-size: 5rem;
`;

// const Icon = styled.div`
// display:flex;
// width:100%;
// justify-content:flex-end;
// justify-self:flex-end;
// align-self:flex-end;
// align-content:flex-end;

// `
// const CloseIcon = styled(CloseOutlinedIcon)`
// display:flex;
// align-self:flex-end;
// justify-self:flex-end;
// margin-left:5rem;
// `
// const Modal = styled.div`
//   position: absolute;
//   top: 5%;
//   z-index: 20;
//   padding:1rem;
//   left: 30%;
//   display: flex;
//   margin-bottom:3rem;
//   justify-content: center;
//   transition: 1s ease-in-out;
//   background:#ffffff;
//   box-shadow:0 4px 8px 0 rgb(0 0 0 / 15%);
//   display:flex;
//   flex-direction:column;
//   align-content:center;
//   justify-content:space-around;
//   width:400px;
//   border-radius:5px;
// //   row-gap:10px;
//   @media (max-width: 1024px) {
//     left: 30%;
//     top: 5%;
//   }
//   @media (max-width: 719px) {
//     left: 15%;
//   }
//   @media (max-width: 570px) {
//     left: 10%;
//   }
//   @media (max-width: 500px) {
//     left: 2%;
//     width:95%;
//   }
//   div{
//     display:grid;
//     grid-template-columns:repeat(2, 45%);
//     column-gap:5%;
//     justify-content:center;
//     h4{
//         margin-bottom:1rem;
//     }
//     p{
//         text-align:right;
        
//     }
//     span{
//         text-align:left;
//         font-weight:bold;

//     }
//   }
//   button {
//     width: 100%;
//     text-align: center;
//     border: none;
//     background-color: #003559;
//     padding: 8px;
//     color: #fff;
//     font-size: 18px;
//     border-radius: 5px;
//     margin-top: 10px;
//     /* opacity: 0.6; */
//     transition: 0.3s ease-in-out;
//     :hover {
//       background-color: #061a40;
//       color: #fff;
//       /* opacity: 1; */
//     }
//   }
// `;
const Backdrop = styled.div`
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 100%, transparent);
  z-index: 15;
  left: 0;
  position: fixed;
  top: 0;
`;
export default DashTable;
