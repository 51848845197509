import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ApiKeyTable from "../Tables/SettingsTable/ApiKeyTable";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PaymentWalletTable from "../Tables/SettingsTable/PaymentWalletTable";
import axios from "../../../api/axios";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Tooltip from "@mui/material/Tooltip";
import AddWalletTable from "../Tables/DashboardTable/AddWalletTable";
import { Button, Modal } from "react-bootstrap";
import { Close, Delete, Warning } from "@mui/icons-material";
import DataTable from 'react-data-table-component';
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import OTPInput from "react-otp-input";




// const user_id = sessionStorage.getItem("userId");
const user_id = localStorage.getItem("userId");
// const timeOut = 60000;
const ADDWALLET_ADDRESS = "/?action=save_wallet";
const API_KEY = "/?action=generate_api";
const GET_API_KEY = "/?action=get_api_key";
const GET_IPN = "/?action=get_ipn";
const GENERATE_IPN = "/?action=generate_ipn";
const PaymentSettings = () => {
  const [addWallet, setAddWallet] = useState(false);
  const [textArea, setTextArea] = useState("");
  // const [copy, setCopy] = useState(false);
  const [coin, setCoin] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [invalidWallet, setInvalidWallet] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [success, setSuccess] = useState(false);
  const [copyed, setCopyed] = useState("Copy");
  const [successMsg, setSuccessMsg] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [ipnKey, setIpnKey] = useState("");
  const [showIpnKey, setShowIpnKey] = useState(false);
  const [getIpnKey, setGetIpnKey] = useState("");
  const [time_out, setTimeout] = useState('')
  const [recurrent, setRecurrent] = useState('')
  const [timeMsg, setTimeMg] = useState('')
  const [recurrMsg, setRecurrMsg] = useState('');
  const [timeSucc, setTimeSucc] = useState(false)
  const [recurrSucc, setRecurrSucc] = useState(false)
  const [authentication, setAuthentication] =useState(false)
  const [show, setShow] = useState(false);
  const [loading4, setLoading4] = useState(false)
  const [loading5, setLoading5] = useState(false)
  const [success2, setSuccess2] = useState(false)
  const [verifyErr, setVerrifyErr] = useState("");
 
  const [OTP, setOTP] = useState("");
  function handleChange(OTP) {
    setOTP(OTP);
    setVerrifyErr('')
  }
  const [refreshTable, setRefresh] = useState(false);
const [data,setData] = useState([])
const [show2, setShow2] = useState(false);
const [id, setAddressId] = useState('')
 
  
const fetchTableData = () => {
  
  axios
    .post('/?action=get_wallet_info', null, {
      params: {
        user_id,
      },
    })
    .then((resp) => {
      
      setData(resp.data.data)
    
    })
    .catch((err) => {
      
    });
  };
  const generateOTP = () => {
      
      setLoading4(true);
      axios
        .post('/?action=generate_tx_otp', null, {
          params: {
            user_id,
            'code':OTP,
          },
        })
        .then((resp) => {
         
          setLoading4(false);

          if (resp.data.status_code === 200) {
           
           const userInfo = resp.data;
          
          setShow(true)
          }
          if (resp.data.status_code === 401) {
            setVerrifyErr(resp.data.message);
          }
        })
        .catch((err) => {
          
          setLoading4(false);
        });
    
  };

  const handle2faVerification = () => {
    
    if (!OTP){
      setVerrifyErr('All input fields are required')
    }
    if (OTP) {
      
      setLoading5(true);
      axios
        .post('/?action=generate_tx_otp', null, {
          params: {
            user_id,
            'code':OTP,
          },
        })
        .then((resp) => {
         
          setLoading5(false);

          if (resp.data.status_code === 200) {
           
           const userInfo = resp.data;
        
          setAddWallet(true)
          setShow(false)

     
          }
          if (resp.data.status_code === 401) {
            setVerrifyErr(resp.data.message);
          }
        })
        .catch((err) => {
          
          setLoading5(false);
        });
    }
  };

  
  const getTimeout = ()=>{
    axios
    .post('/?action=get_time_out', null, {
      params: {
        user_id,
      },
    })
    .then((resp) => {
      
      setTimeout(resp.data.data[0].time_out);
      setTimeMg('')
     
    })
    .catch((err) => {
      
      
    });
  }
  useEffect(() => {
    window.addEventListener("load", getTimeout);
    return () => {
      window.removeEventListener("load", getTimeout);
    };
  }, []);
  const getRecurrent = ()=>{
    axios
    .post('/?action=get_recurrent', null, {
      params: {
        user_id,
       
      },
    })
    .then((resp) => {
      setRecurrent(resp.data.data[0].recurrent);
      setRecurrMsg('')
     
    })
    .catch((err) => {
      
      
    });
  }
  useEffect(() => {
    window.addEventListener("load", getRecurrent);
    return () => {
      window.removeEventListener("load", getRecurrent);
    };
  }, []);
  
const updateTimeout = ()=>{
  axios
  .post('/?action=update_time_out', null, {
    params: {
      user_id,
      time_out
    },
  })
  .then((resp) => {
     setTimeMg('Updated successfully')
    setTimeSucc(true)
   
  })
  .catch((err) => {
    
    
  });
}

const updateRecurrent = ()=>{
  axios
  .post('/?action=update_recurrent', null, {
    params: {
      user_id,
      recurrent
    },
  })
  .then((resp) => {
    setRecurrMsg('Updated successfully')
    setRecurrSucc(true)
  })
  .catch((err) => {
    
    
  });
}

useEffect(()=>{
  updateRecurrent()
},[recurrent])

useEffect(()=>{
  updateTimeout()
},[time_out])



  const hideSuccessMsg = () => {
    setSuccessMsg("");
  };

  const handleCopy = () => {
    setCopyed("Copy");
  };
  // setTimeout(handleCopy, 4000);
  const copyText = () => {
    navigator.clipboard.writeText(textArea);
    setCopyed("Copied");
  };
  const copyKey = () => {
    navigator.clipboard.writeText(ipnKey);
    setCopyed("Copied");
  };

  const addAnotherWallet = () => {
    if (coin && walletAddress) {
      setLoading(true);
      axios
        .post(ADDWALLET_ADDRESS, null, {
          params: {
            user_id,
            address: walletAddress,
            coin,
          },
        })
        .then((resp) => {
          setLoading(false);
          setCoin("");
          setWalletAddress("");
          setInvalidWallet(resp.data.message);
          setAddWallet(false);
          setRefresh(true)
          fetchTableData()
        })
        .catch((err) => {
          
          setLoading(false);
        });
    } else {
      setInvalidWallet("Please enter a valid wallet address");
    }
  };

  const getApiKey = () => {

    axios
      .post(GET_API_KEY, null, {
        params: {
          user_id,
        },
      })
      .then((resp) => {
        const data = resp.data.data[0].api_key;
        setApiKey(data);
      })
      .catch((err) => {
        
      });
  };
  useEffect(() => {
    window.addEventListener("load", getApiKey);
    return () => {
      window.removeEventListener("load", getApiKey);
    };
  }, []);

  const generateAPIkey = () => {
    setLoading2(true);
    axios
      .post(API_KEY, null, {
        params: {
          user_id,
        },
      })
      .then((resp) => {
        setLoading2(false);
        setTextArea(resp.data.data[0].api_key);
        setSuccess(true);
        setSuccessMsg("API key generated Successfully");
        getApiKey();
      })
      .catch((err) => {
        
        setLoading2(false);
      });
  };

  // setInterval(hideSuccessMsg, timeOut);

  const getIPNKey = () => {

    axios
      .post(GET_IPN, null, {
        params: {
          user_id,
        },
      })
      .then((resp) => {
        const data = resp.data.data[0].ipn_key;
        setIpnKey(data);
      })
      .catch((err) => {
        
      });
  };
  useEffect(() => {
    window.addEventListener("load", getIPNKey);
    return () => {
      window.removeEventListener("load", getIPNKey);
    };
  }, []);

  const generateIPNkey = () => {
    setLoading3(true);
    axios
      .post(GENERATE_IPN, null, {
        params: {
          user_id,
        },
      })
      .then((resp) => {
        setLoading3(false);
        setIpnKey(resp.data.data[0].ipn_key);
        setShowIpnKey(true);
        // setSuccess(true);
      })
      .catch((err) => {
        
        setLoading3(false);
      });
  };

  useEffect(()=>{
    setInterval(()=>{setTimeMg('')},6000)
  },[time_out])

  useEffect(()=>{
    setInterval(()=>{setRecurrMsg('')},6000)
  },[recurrent])
useEffect(()=>{
  setRecurrMsg('')
  setTimeMg('')
},[])

useEffect(()=>{
  getIPNKey()
  getApiKey()
  getRecurrent()
  getTimeout()
},[])
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

///////////


const handleClose2 = () => setShow2(false);
const handleShow2 = () => setShow2(true);

const columns = [
  {
      name: 'Currency',
      selector: row => row.coin,
      sortable: true,
  },
  {
      name: 'Wallet Address',
      selector: row => row.address,
      sortable: true,
  },

  {
    name: 'Action',
    sortable: false,
     selector:(row) =>

    <Button style={{'background':'none','border':'none','color':'#061a40'}} onClick={() => {handleShow2(true);
    setAddressId(row.id);
  } }><Delete /></Button>
},


];



// Deleting Address

const deleteAddress = () => {
setloading6(true)
axios
.post('/?action=delete_wallet', null, {
  params: {
    id,
    user_id,
  },
})
.then((resp) => {
  setloading6(false)
  setShow2(false)
  fetchTableData()
  
  
})
.catch((err) => {
    setloading6(false)
    
  });
};



const customStyles = {
rows: {
    style: {
        minHeight: '50px', // override the row height
    },
},
headCells: {
    style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        textAlign:'center'
    },
},
cells: {
    style: {
        paddingLeft: '10px', // override the cell padding for data cells
        paddingRight: '10px',
        textAlign:'center'

    },
},
};


const [loading6, setloading6] = useState(false)

useEffect(()=>{
  fetchTableData()
},[])


useEffect(() => {
  window.addEventListener("load", fetchTableData);
  return () => {
    window.removeEventListener("load", fetchTableData);
  };  
}, []);


/////////
  return (
    <>
    {
      loading4 && <Backdrop/>
    }
    {
      loading4 && <Loading />
    }
  
    <ContainerFluid>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container4>
          <h2>Two Factor Authentication</h2>
          <span>
            Please check your inbox or use your authenticator app for verification
           
          </span>
          <div className="otp">
         <OTPInput
          onChange={handleChange}
            value={OTP}
            inputStyle="inputStyle"
            numInputs={6}
            separator={<span></span>}
          />
   
         </div>
   
          <p
            style={{
              color: "red",
              "font-weight": "lighter",
              "margin-bottom": "-5px",
              "margin-top": "5px",
            }}
          >
            {verifyErr}
          </p>
          <button disabled={loading5} onClick={handle2faVerification}>
            {loading5 ? <MyCircularProgress size="1.5rem" /> : "Verify"}
          </button>
          <p>Can't find it? Please check your spam folder</p>
        </Container4>

        </Modal.Body>
       
      </Modal>
      <Container>
        <Header>
          <h4>Payment wallet</h4>
          <BaseCurrency>
            <label for="coins">Base Currency:</label>
            <select name="coins" id="coinsSelection">
              <option value="select">Select coin</option>
              <option value="BTC">BTC</option>
              <option value="USDT">USDT</option>
              <option value="DOGE">DOGE</option>
              <option value="ETHERUM">ETHERUM</option>
            </select>
          </BaseCurrency>
        </Header>
        <CardContainer>
          <Table>
            {/* <PaymentWalletTable /> */}
            {/* <AddWalletTable refresh={refreshTable} /> */}
            <DataTable
            columns={columns}
            data={data}
            pagination
            customStyles={customStyles}
           
        />
          </Table>
          {/* <p>You have not defined your wallet to receive payments.</p> */}
          <p
            style={{
              color: "red",
              "font-weight": "lighter",
              "margin-bottom": "-5px",
              "margin-top": "5px",
            }}
          >
            {invalidWallet}
          </p>
          <AddwalletInput show={addWallet}>
            <select
              name="coins"
              id="coinsSelection"
              onChange={(e) => setCoin(e.target.value)}
              value={coin}
            >
              <option value="select">Select coin</option>
              <option value="BTC">BTC</option>
              <option value="USDT">USDT</option>
              <option value="DOGE">DOGE</option>
              <option value="ETHERUM">ETHERUM</option>
            </select>
            <input
              type="text"
              placeholder="Wallet Address"
              onInput={() => setInvalidWallet("")}
              onChange={(e) => setWalletAddress(e.target.value)}
              value={walletAddress}
            />
            <ActionBTN>
              <button disabled={loading} onClick={addAnotherWallet}>
                {loading ? <MyCircularProgress size="1.5rem" /> : "Add"}
              </button>
              <span
                onClick={() => setAddWallet(false)}
                className={addWallet ? " " : "hide"}
              >
                <CloseOutlinedIcon />
              </span>
            </ActionBTN>
          </AddwalletInput>
          <Buttons show={addWallet}>
            <button onClick={generateOTP}>
              Add another wallet
            </button>
          </Buttons>
        </CardContainer>
      </Container>
      {/* Next Section */}
      <h4>API Keys</h4>
      <Container2>
        {/* <Table>
          <ApiKeyTable />
        </Table> */}
        <p>Please define you wallet before creating API key.</p>
       {
        apiKey && (
          <SaveName>
          <p style={{ "font-weight": "lighter" }}>{apiKey}</p>
          <div>
            {" "}
            <Tooltip title={copyed} placement="top-start" arrow>
              <Copy onClick={copyText} />
            </Tooltip>
          </div>
        </SaveName>
        )
       }

        <p
          style={{
            color: "green",
            "font-weight": "lighter",
            "margin-bottom": "-5px",
            "margin-top": "5px",
          }}
        >
          {successMsg}
        </p>

        <button disabled={loading2} onClick={generateAPIkey}>
          {loading2 ? (
            <MyCircularProgress size="1.5rem" />
          ) : apiKey ? (
            "Generate another API"
          ) : (
            "Generate API"
          )}
        </button>
      </Container2>
      <h4>Instant Payment Notification</h4>
      <Container3>
        <span>IPN secret key</span>
        <h6>Note! this key can only be viewed once</h6>
        {
          ipnKey && (
            <SaveName>
            <p style={{ "margin-top": "25px",}}>{ipnKey}</p>
          <div>
            <Tooltip title={copyed} placement="top-start" arrow>
              <Copy onClick={copyKey} />
            </Tooltip>
          </div>
        </SaveName>
          )
        }

        <button disabled={loading3} onClick={generateIPNkey}>
          {loading3 ? <MyCircularProgress size="1.5rem" /> : "Generate"}
        </button>

        <span>Recurent notifications</span>
        <Label>Timeout</Label>
        <p style={{'margin-top':'-10px', 'margin-bottom':'-5px','color':'green'}}>{ timeSucc ? timeMsg : ''}</p>
        <input type="number" placeholder="0" onInput={(e)=> setTimeout(e.target.value)} value={time_out}  />

        <Label>Number of recurrent notifications</Label>
        <p style={{'margin-top':'-10px', 'margin-bottom':'-5px','color':'green'}}>{ recurrSucc ? recurrMsg : ''}</p>
        <input type="number" placeholder="0" onInput={(e)=> setRecurrent(e.target.value)} value={recurrent} />
      </Container3>
    </ContainerFluid>
    
    <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton style={{'border':'none'}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body>
          <Icon>
          {" "}
          <Checked />
        </Icon>
        <h3>Delete Wallet Address?</h3>
        <span>Are you sure you want to delete this wallet address?  </span>
        <span>You can't undo this action.</span>

          </Body>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose2}>
            Cancel
          </Button>
         
          <Button variant="danger"  onClick={deleteAddress}>
                  {loading? (
                    <MyCircularProgress size="1.5rem" />
                  ) : (
                    "Delete"
                  )}
                </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Loading = styled(CircularProgress)`
position:fixed;
top:40%;
left:45%;
z-index:20;
// color:#fff !important;
`
const Backdrop = styled.div`
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 100%, transparent);
  z-index: 15;
  left: 0;
  position: fixed;
  top: 0;
`;
const SaveName = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  border: none;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
  border-radius: 5px;
  background: #f5f5f5;
  padding: 10px;
  font-size: 18px;
  align-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  p {
    /* margin-top:10px;
    margin-left:10px; */
    color: rgba(0, 0, 0, 0.7) !important;
    font-size: 20px !important;
    line-height: 0;
  }
  div {
    cursor: pointer;
    background: #ffffff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-top: 2px;
    /* margin-right:10px; */
    :hover {
      background: red;
      color: #fff;
    }
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const Copy = styled(ContentCopyOutlinedIcon)`
  margin-top: 10px;
  margin-left: 8px;
`;
const BaseCurrency = styled.div``;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    font-weight: bold;
    margin-right: 10px;
  }
  select {
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    :focus {
      outline: none;
    }
  }
`;
const Label = styled.div``;
const ContainerFluid = styled.div`
  h4 {
    font-weight: bold;
  }
`;

const MyCircularProgress = styled(CircularProgress)`
  color: #fff !important;
`;
const Container3 = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 2rem;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
  color: rgba(0, 0, 0, 0.7);
 
  span {
    font-weight: bold;
  }

  input {
    width: 40%;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    @media (max-width: 400px) {
      width: 100%;
    }

    :focus {
      outline: none;
    }
  }
  button {
    border: none;
    border-radius: 5px;
    padding: 8px 32px;
    background-color: #f43b47;
    color: #fff;
    width: fit-content;
  }
`;
const Container = styled.div`
  margin-bottom: 3rem;
`;
const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1.5rem;

  p {
    font-size: 16px;
    padding: 10px;
    /* background: rgba(0, 0, 0, 0.1); */
    color: #f43b47;
    border-radius: 5px;
    font-weight: 300;
    margin-top: 10px;
    /* text-align:center; */
  }
`;
const Table = styled.div`
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
  border-radius: 5px; */
`;
const AddwalletInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
  flex-wrap: wrap;
  row-gap: 1rem;
  transition: 0.3s ease-in-out;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transform: ${(props) => (props.show ? "translateY(0)" : "translateY(-20px)")};
  @media (max-width: 400px) {
    flex-direction: column;
  }
  select {
    width: 20%;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    @media (max-width: 400px) {
      width: 100%;
    }

    :focus {
      outline: none;
    }
  }
  input {
    width: 50%;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    margin-left: -5%;
    @media (max-width: 400px) {
      width: 100%;
      margin-left: 0;
    }

    :focus {
      outline: none;
    }
  }
`;
const Container2 = styled(CardContainer)`
  margin-bottom: 3rem;
  input {
    width: 40%;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-right: 3rem;
    color: rgba(0, 0, 0, 0.7);

    :focus {
      outline: none;
    }
  }
  button {
    border: none;
    border-radius: 5px;
    padding: 8px 32px;
    background-color: #f43b47;
    color: #fff;
    /* background-image: linear-gradient(to right bottom, #e8962e, #e45131) !important; */
  }
`;
const AddKey = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  button {
    border: 1px solid mediumseagreen;
    background: none;
    color: mediumseagreen;
    transition: 0.3s ease-in-out;
    :hover {
      background: mediumseagreen;
      color: #fff;
    }
  }
`;

const ActionBTN = styled.div`
  align-self: center;
  margin-right: 3%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  color: #f43b47;
  transition: 0.2s ease-in-out;
  width: 10%;
  button {
    border-radius: 5px;
    border: none;
    /* padding:8px; */
    padding: 8px 16px;
    background: mediumseagreen;
    color: #fff;
  }
  span {
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    color: #f43b47;
    transition: 0.3s ease-in-out;
    :hover {
      background: #f43b47;
      color: #fff;
    }
  }
  @media (max-width: 400px) {
    width: 100%;
    margin-right: 0;
  }
`;

const Body = styled.div`
display: flex;
flex-direction: column;
align-content:center;
align-self:center;
column-gap:10px;
h3{
  text-align-center !important;
  align-self:center;
}
span{
  align-self:center;
  text-align-center;

}
`
const Checked = styled(Warning)`
  color: #fff;
  font-size: 56px !important;
`;
const Icon = styled.div`
  padding: 1rem;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #f43b47;
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  margin-top:-10px;
  margin-bottom:10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
  margin-top: 2rem;
  transition: 0.3s ease-in-out;
  transform: ${(props) => (props.show ? "translateY(0)" : "translateY(-4rem)")};

  button {
    border: none;
    border-radius: 5px;
    z-index: 1;
    padding: 8px 32px;
    background-color: #f43b47;
    color: #fff;
  }
`;

const InputsContainer = styled.div`
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  @media (max-width: 512px) {
    width: 100%;
  }
`;
const Inputs = styled.div`
  display: flex;
  justify-content: space-around;
  input {
    width: 50px;
    height: 50px;
    border: none;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
    border-radius: 5px;
    background: #ffffff;
    font-size: 32px;
    text-align: center;
    @media (max-width: 348px) {
      width: 40px;
      height: 40px;
    }
  }
`;
const Label2 = styled.h5`
  text-align: center;
`;
const Main = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  /* align-content: center; */
`;

const Container4 = styled.div`
  width: 500px;
  height: fit-content;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;

 
  background: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-align: center;
  button {
    width: 400px;
    margin: 3rem auto auto auto;

    text-align: center;
    border: none;
    background-color: #003559;
    padding: 8px;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 10px;
    /* opacity: 0.6; */
    transition: 0.3s ease-in-out;
    :hover {
      background-color: #061a40;
      color: #fff;
      /* opacity: 1; */
    }
  }
  @media (max-width: 512px) {
    width: 95%;
    button {
      width: 100%;
    }
  }
`;

export default PaymentSettings;
