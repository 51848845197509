import { Close, Delete, Warning } from "@mui/icons-material";
import React, {  useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import axios from "../../../../api/axios";
import styled from "styled-components";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { CircularProgress } from "@mui/material";

// const user_id = sessionStorage.getItem("userId");

const user_id = localStorage.getItem('userId');
function AddWalletTable({refresh}) {
  
  const [data,setData] = useState([])
  const [show2, setShow2] = useState(false);
  const [id, setAddressId] = useState('')

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
 
const columns = [
    {
        name: 'Currency',
        selector: row => row.coin,
        sortable: true,
    },
    {
        name: 'Wallet Address',
        selector: row => row.address,
        sortable: true,
    },
  
    {
      name: 'Action',
      sortable: false,
       selector:(row) =>

      <Button style={{'background':'none','border':'none','color':'#061a40'}} onClick={() => {handleShow2(true);
      setAddressId(row.id);
    } }><Delete /></Button>
  },
  
  
];


const fetchTableData = () => {
  console.log("fetching table data");
  axios
    .post('/?action=get_wallet_info', null, {
      params: {
        user_id,
      },
    })
    .then((resp) => {
      console.log(resp.data);
      setData(resp.data.data)
    
    })
    .catch((err) => {
      console.log(err);
    });
};

// Deleting Address

const deleteAddress = () => {
  console.log("deleting address");
  setloading6(true)
  axios
  .post('/?action=delete_wallet', null, {
    params: {
      id,
      user_id,
    },
  })
  .then((resp) => {
    console.log(resp.data);
    setloading6(false)
    setShow2(false)
    fetchTableData()
    
    
  })
  .catch((err) => {
      setloading6(false)
      console.log(err);
    });
};



const customStyles = {
  rows: {
      style: {
          minHeight: '50px', // override the row height
      },
  },
  headCells: {
      style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
          textAlign:'center'
      },
  },
  cells: {
      style: {
          paddingLeft: '10px', // override the cell padding for data cells
          paddingRight: '10px',
          textAlign:'center'

      },
  },
};


  const [loading6, setloading6] = useState(false)
 
  useEffect(()=>{
    fetchTableData()
  },[])
  

  useEffect(() => {
    window.addEventListener("load", fetchTableData);
    return () => {
      window.removeEventListener("load", fetchTableData);
    };  
  }, []);
  
    return (
      <>
        <DataTable
            columns={columns}
            data={data}
            pagination
            customStyles={customStyles}
           
        />
        
        <Modal show2={show2} onHide={handleClose2}>
        <Modal.Header closeButton style={{'border':'none'}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body>
          <Icon>
          {" "}
          <Checked />
        </Icon>
        <h3>Delete Wallet Address?</h3>
        <span>Are you sure you want to delete this wallet address?  </span>
        <span>You can't undo this action.</span>

          </Body>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose2}>
            Cancel
          </Button>
         
          <Button variant="danger"  onClick={deleteAddress}>
                  {loading6? (
                    <MyCircularProgress size="1.5rem" />
                  ) : (
                    "Delete"
                  )}
                </Button>
        </Modal.Footer>
      </Modal>
      </>
    );
};
const MyCircularProgress = styled(CircularProgress)`
  color: #fff !important;
`;

const Body = styled.div`
display: flex;
flex-direction: column;
align-content:center;
align-self:center;
column-gap:10px;
h3{
  text-align-center !important;
  align-self:center;
}
span{
  align-self:center;
  text-align-center;

}
`
const Checked = styled(Warning)`
  color: #fff;
  font-size: 56px !important;
`;
const Icon = styled.div`
  padding: 1rem;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #f43b47;
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  margin-top:-10px;
  margin-bottom:10px;
`;
export default AddWalletTable;
