import React from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";


const LoadingFlag = ()=>{
    return <Container><Loading /></Container>
}


const Container = styled.div`
background:rgba(0,0,0,0.2);
height:100vh;
width:100vw;
`
const Loading = styled(CircularProgress)`
position:fixed;
top:40%;
left:45%;
color:#fff !important;
`
export default LoadingFlag;
