import React, {useState, useEffect} from "react";
import PurchaseTable from "./Tables/DashboardTable/PurchasesTable";
import PaymentTable from "./Tables/DashboardTable/PaymentTable";
import DataTable from 'react-data-table-component';
import { useLocation } from "react-router";
import axios from "../../api/axios";




// const user_id = sessionStorage.getItem("userId");
const user_id = localStorage.getItem("userId");

const Payment = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [success,setSuccess] = useState(false)
  
const columns = [
    {
        name: 'Order  ID',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'Amount Sent',
        selector: row => row.amount,
        sortable: true,
    },
    {
        name: 'Amount Received',
        selector: row => row.amount_received,
        sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.created_at,
      sortable: true,
      conditionalCellStyles:[
        {
          when: row => row.created_at,
          style: {
              marginLeft:'-10px'
            
          },
      },
      ]
  },
  {
    name: 'Status',
    selector: row => row.status,
    conditionalCellStyles: [
      {
          when: row => row.status === 'completed',
          style: {
              
              color: 'green',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
      {
          when: row => row.status === 'pending',
          style: {
              
              color: 'black',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
      {
          when: row => row.status === 'partially_paid',
          style: {
              
              color: 'orange',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
      {
          when: row => row.status === 'incoming',
          style: {
              
              color: 'blue',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
      {
          when: row => row.status === 'cancelled',
          style: {
              
              color: 'red',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      },
  ],
    sortable: true,
},
];

const fetchTableData = () => {
  
  setLoading(true)
  axios
    .post('/?action=get_all_tx', null, {
      params: {
        user_id,
      },
    })
    .then((resp) => {
      setData(resp.data.data);
      setSuccess(true)
      setLoading(false)
    
    })
    .catch((err) => {
      setLoading(false)
    });
};
const customStyles = {
  rows: {
      style: {
          minHeight: '50px', // override the row height
      },
  },
  headCells: {
      style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
          textAlign:'center'
      },
  },
  cells: {
      style: {
          paddingLeft: '10px', // override the cell padding for data cells
          paddingRight: '10px',

      },
  },
};
const location = useLocation()
useEffect(()=>{
  fetchTableData()
},[location.key])
// const customSort = (rows, selector, direction) => {
//   return rows.sort((rowA, rowB) => {
//    // use the selector function to resolve your field names by passing the sort comparitors
//    const aField = selector(rowA)
//    const bField = selector(rowB)
 
//    let comparison = 0;
 
//    if (aField > bField) {
//     comparison = 1;
//    } else if (aField < bField) {
//     comparison = -1;
//    }
 
//    return direction === 'desc' ? comparison * -1 : comparison;
//   });
//  };
  return (
    <>
    <h3>All Payments</h3>


    <DataTable
    columns={columns}
    data={data}
    pagination
    customStyles={customStyles}/> 
   
    </>
  );
};

export default Payment;
