import React from "react";
// import { useAuth } from "../auth/auth";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const   UserDetailsAuth = ({ children }) => {
    const emailVerified = sessionStorage.getItem('emailVerified')
    
  if (emailVerified !=='true') {
    return <Navigate to="/unauthenticated_url-request" />;
  }
  return <div>{children}</div>;
};

export default  UserDetailsAuth;
